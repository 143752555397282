import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap';

class TaxTable extends React.Component {
  render() {
    const { data, location } = this.props;

    return (
      <Layout data={data} location={location}>
        <h1>{data.markdownRemark.frontmatter.title}</h1>
        <iframe className='embed-responsive' height={'1000px'} title={data.markdownRemark.frontmatter.title} src={data.markdownRemark.frontmatter.url}></iframe>
      </Layout>
    );
  }
}

export default TaxTable;

export const toolQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        template
        type
        red_track
        description
        url
      }
    }
  }
`;